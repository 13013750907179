import { createDecorator } from '../../instantiation/common/instantiation.js';
export const IProgressService = createDecorator('progressService');
export const emptyProgressRunner = Object.freeze({
  total() {},
  worked() {},
  done() {}
});
export class Progress {
  static {
    this.None = Object.freeze({
      report() {}
    });
  }
  constructor(callback) {
    this.callback = callback;
  }
  report(item) {
    this._value = item;
    this.callback(this._value);
  }
}
export const IEditorProgressService = createDecorator('editorProgressService');