import { localize } from '../../../nls.js';
import { createDecorator } from '../../instantiation/common/instantiation.js';
export const IAccessibilitySignalService = createDecorator('accessibilitySignalService');
/** Make sure you understand the doc comments of the method you want to call when using this token! */
export const AcknowledgeDocCommentsToken = Symbol('AcknowledgeDocCommentsToken');
/**
 * Corresponds to the audio files in ./media.
*/
export class Sound {
  static register(options) {
    const sound = new Sound(options.fileName);
    return sound;
  }
  static {
    this.error = Sound.register({
      fileName: 'error.mp3'
    });
  }
  static {
    this.warning = Sound.register({
      fileName: 'warning.mp3'
    });
  }
  static {
    this.success = Sound.register({
      fileName: 'success.mp3'
    });
  }
  static {
    this.foldedArea = Sound.register({
      fileName: 'foldedAreas.mp3'
    });
  }
  static {
    this.break = Sound.register({
      fileName: 'break.mp3'
    });
  }
  static {
    this.quickFixes = Sound.register({
      fileName: 'quickFixes.mp3'
    });
  }
  static {
    this.taskCompleted = Sound.register({
      fileName: 'taskCompleted.mp3'
    });
  }
  static {
    this.taskFailed = Sound.register({
      fileName: 'taskFailed.mp3'
    });
  }
  static {
    this.terminalBell = Sound.register({
      fileName: 'terminalBell.mp3'
    });
  }
  static {
    this.diffLineInserted = Sound.register({
      fileName: 'diffLineInserted.mp3'
    });
  }
  static {
    this.diffLineDeleted = Sound.register({
      fileName: 'diffLineDeleted.mp3'
    });
  }
  static {
    this.diffLineModified = Sound.register({
      fileName: 'diffLineModified.mp3'
    });
  }
  static {
    this.chatRequestSent = Sound.register({
      fileName: 'chatRequestSent.mp3'
    });
  }
  static {
    this.chatResponseReceived1 = Sound.register({
      fileName: 'chatResponseReceived1.mp3'
    });
  }
  static {
    this.chatResponseReceived2 = Sound.register({
      fileName: 'chatResponseReceived2.mp3'
    });
  }
  static {
    this.chatResponseReceived3 = Sound.register({
      fileName: 'chatResponseReceived3.mp3'
    });
  }
  static {
    this.chatResponseReceived4 = Sound.register({
      fileName: 'chatResponseReceived4.mp3'
    });
  }
  static {
    this.clear = Sound.register({
      fileName: 'clear.mp3'
    });
  }
  static {
    this.save = Sound.register({
      fileName: 'save.mp3'
    });
  }
  static {
    this.format = Sound.register({
      fileName: 'format.mp3'
    });
  }
  static {
    this.voiceRecordingStarted = Sound.register({
      fileName: 'voiceRecordingStarted.mp3'
    });
  }
  static {
    this.voiceRecordingStopped = Sound.register({
      fileName: 'voiceRecordingStopped.mp3'
    });
  }
  static {
    this.progress = Sound.register({
      fileName: 'progress.mp3'
    });
  }
  constructor(fileName) {
    this.fileName = fileName;
  }
}
export class SoundSource {
  constructor(randomOneOf) {
    this.randomOneOf = randomOneOf;
  }
}
export class AccessibilitySignal {
  constructor(sound, name, legacySoundSettingsKey, settingsKey, legacyAnnouncementSettingsKey, announcementMessage) {
    this.sound = sound;
    this.name = name;
    this.legacySoundSettingsKey = legacySoundSettingsKey;
    this.settingsKey = settingsKey;
    this.legacyAnnouncementSettingsKey = legacyAnnouncementSettingsKey;
    this.announcementMessage = announcementMessage;
  }
  static {
    this._signals = new Set();
  }
  static register(options) {
    const soundSource = new SoundSource('randomOneOf' in options.sound ? options.sound.randomOneOf : [options.sound]);
    const signal = new AccessibilitySignal(soundSource, options.name, options.legacySoundSettingsKey, options.settingsKey, options.legacyAnnouncementSettingsKey, options.announcementMessage);
    AccessibilitySignal._signals.add(signal);
    return signal;
  }
  static {
    this.errorAtPosition = AccessibilitySignal.register({
      name: localize('accessibilitySignals.positionHasError.name', 'Error at Position'),
      sound: Sound.error,
      announcementMessage: localize('accessibility.signals.positionHasError', 'Error'),
      settingsKey: 'accessibility.signals.positionHasError',
      delaySettingsKey: 'accessibility.signalOptions.delays.errorAtPosition'
    });
  }
  static {
    this.warningAtPosition = AccessibilitySignal.register({
      name: localize('accessibilitySignals.positionHasWarning.name', 'Warning at Position'),
      sound: Sound.warning,
      announcementMessage: localize('accessibility.signals.positionHasWarning', 'Warning'),
      settingsKey: 'accessibility.signals.positionHasWarning',
      delaySettingsKey: 'accessibility.signalOptions.delays.warningAtPosition'
    });
  }
  static {
    this.errorOnLine = AccessibilitySignal.register({
      name: localize('accessibilitySignals.lineHasError.name', 'Error on Line'),
      sound: Sound.error,
      legacySoundSettingsKey: 'audioCues.lineHasError',
      legacyAnnouncementSettingsKey: 'accessibility.alert.error',
      announcementMessage: localize('accessibility.signals.lineHasError', 'Error on Line'),
      settingsKey: 'accessibility.signals.lineHasError'
    });
  }
  static {
    this.warningOnLine = AccessibilitySignal.register({
      name: localize('accessibilitySignals.lineHasWarning.name', 'Warning on Line'),
      sound: Sound.warning,
      legacySoundSettingsKey: 'audioCues.lineHasWarning',
      legacyAnnouncementSettingsKey: 'accessibility.alert.warning',
      announcementMessage: localize('accessibility.signals.lineHasWarning', 'Warning on Line'),
      settingsKey: 'accessibility.signals.lineHasWarning'
    });
  }
  static {
    this.foldedArea = AccessibilitySignal.register({
      name: localize('accessibilitySignals.lineHasFoldedArea.name', 'Folded Area on Line'),
      sound: Sound.foldedArea,
      legacySoundSettingsKey: 'audioCues.lineHasFoldedArea',
      legacyAnnouncementSettingsKey: 'accessibility.alert.foldedArea',
      announcementMessage: localize('accessibility.signals.lineHasFoldedArea', 'Folded'),
      settingsKey: 'accessibility.signals.lineHasFoldedArea'
    });
  }
  static {
    this.break = AccessibilitySignal.register({
      name: localize('accessibilitySignals.lineHasBreakpoint.name', 'Breakpoint on Line'),
      sound: Sound.break,
      legacySoundSettingsKey: 'audioCues.lineHasBreakpoint',
      legacyAnnouncementSettingsKey: 'accessibility.alert.breakpoint',
      announcementMessage: localize('accessibility.signals.lineHasBreakpoint', 'Breakpoint'),
      settingsKey: 'accessibility.signals.lineHasBreakpoint'
    });
  }
  static {
    this.inlineSuggestion = AccessibilitySignal.register({
      name: localize('accessibilitySignals.lineHasInlineSuggestion.name', 'Inline Suggestion on Line'),
      sound: Sound.quickFixes,
      legacySoundSettingsKey: 'audioCues.lineHasInlineSuggestion',
      settingsKey: 'accessibility.signals.lineHasInlineSuggestion'
    });
  }
  static {
    this.terminalQuickFix = AccessibilitySignal.register({
      name: localize('accessibilitySignals.terminalQuickFix.name', 'Terminal Quick Fix'),
      sound: Sound.quickFixes,
      legacySoundSettingsKey: 'audioCues.terminalQuickFix',
      legacyAnnouncementSettingsKey: 'accessibility.alert.terminalQuickFix',
      announcementMessage: localize('accessibility.signals.terminalQuickFix', 'Quick Fix'),
      settingsKey: 'accessibility.signals.terminalQuickFix'
    });
  }
  static {
    this.onDebugBreak = AccessibilitySignal.register({
      name: localize('accessibilitySignals.onDebugBreak.name', 'Debugger Stopped on Breakpoint'),
      sound: Sound.break,
      legacySoundSettingsKey: 'audioCues.onDebugBreak',
      legacyAnnouncementSettingsKey: 'accessibility.alert.onDebugBreak',
      announcementMessage: localize('accessibility.signals.onDebugBreak', 'Breakpoint'),
      settingsKey: 'accessibility.signals.onDebugBreak'
    });
  }
  static {
    this.noInlayHints = AccessibilitySignal.register({
      name: localize('accessibilitySignals.noInlayHints', 'No Inlay Hints on Line'),
      sound: Sound.error,
      legacySoundSettingsKey: 'audioCues.noInlayHints',
      legacyAnnouncementSettingsKey: 'accessibility.alert.noInlayHints',
      announcementMessage: localize('accessibility.signals.noInlayHints', 'No Inlay Hints'),
      settingsKey: 'accessibility.signals.noInlayHints'
    });
  }
  static {
    this.taskCompleted = AccessibilitySignal.register({
      name: localize('accessibilitySignals.taskCompleted', 'Task Completed'),
      sound: Sound.taskCompleted,
      legacySoundSettingsKey: 'audioCues.taskCompleted',
      legacyAnnouncementSettingsKey: 'accessibility.alert.taskCompleted',
      announcementMessage: localize('accessibility.signals.taskCompleted', 'Task Completed'),
      settingsKey: 'accessibility.signals.taskCompleted'
    });
  }
  static {
    this.taskFailed = AccessibilitySignal.register({
      name: localize('accessibilitySignals.taskFailed', 'Task Failed'),
      sound: Sound.taskFailed,
      legacySoundSettingsKey: 'audioCues.taskFailed',
      legacyAnnouncementSettingsKey: 'accessibility.alert.taskFailed',
      announcementMessage: localize('accessibility.signals.taskFailed', 'Task Failed'),
      settingsKey: 'accessibility.signals.taskFailed'
    });
  }
  static {
    this.terminalCommandFailed = AccessibilitySignal.register({
      name: localize('accessibilitySignals.terminalCommandFailed', 'Terminal Command Failed'),
      sound: Sound.error,
      legacySoundSettingsKey: 'audioCues.terminalCommandFailed',
      legacyAnnouncementSettingsKey: 'accessibility.alert.terminalCommandFailed',
      announcementMessage: localize('accessibility.signals.terminalCommandFailed', 'Command Failed'),
      settingsKey: 'accessibility.signals.terminalCommandFailed'
    });
  }
  static {
    this.terminalCommandSucceeded = AccessibilitySignal.register({
      name: localize('accessibilitySignals.terminalCommandSucceeded', 'Terminal Command Succeeded'),
      sound: Sound.success,
      announcementMessage: localize('accessibility.signals.terminalCommandSucceeded', 'Command Succeeded'),
      settingsKey: 'accessibility.signals.terminalCommandSucceeded'
    });
  }
  static {
    this.terminalBell = AccessibilitySignal.register({
      name: localize('accessibilitySignals.terminalBell', 'Terminal Bell'),
      sound: Sound.terminalBell,
      legacySoundSettingsKey: 'audioCues.terminalBell',
      legacyAnnouncementSettingsKey: 'accessibility.alert.terminalBell',
      announcementMessage: localize('accessibility.signals.terminalBell', 'Terminal Bell'),
      settingsKey: 'accessibility.signals.terminalBell'
    });
  }
  static {
    this.notebookCellCompleted = AccessibilitySignal.register({
      name: localize('accessibilitySignals.notebookCellCompleted', 'Notebook Cell Completed'),
      sound: Sound.taskCompleted,
      legacySoundSettingsKey: 'audioCues.notebookCellCompleted',
      legacyAnnouncementSettingsKey: 'accessibility.alert.notebookCellCompleted',
      announcementMessage: localize('accessibility.signals.notebookCellCompleted', 'Notebook Cell Completed'),
      settingsKey: 'accessibility.signals.notebookCellCompleted'
    });
  }
  static {
    this.notebookCellFailed = AccessibilitySignal.register({
      name: localize('accessibilitySignals.notebookCellFailed', 'Notebook Cell Failed'),
      sound: Sound.taskFailed,
      legacySoundSettingsKey: 'audioCues.notebookCellFailed',
      legacyAnnouncementSettingsKey: 'accessibility.alert.notebookCellFailed',
      announcementMessage: localize('accessibility.signals.notebookCellFailed', 'Notebook Cell Failed'),
      settingsKey: 'accessibility.signals.notebookCellFailed'
    });
  }
  static {
    this.diffLineInserted = AccessibilitySignal.register({
      name: localize('accessibilitySignals.diffLineInserted', 'Diff Line Inserted'),
      sound: Sound.diffLineInserted,
      legacySoundSettingsKey: 'audioCues.diffLineInserted',
      settingsKey: 'accessibility.signals.diffLineInserted'
    });
  }
  static {
    this.diffLineDeleted = AccessibilitySignal.register({
      name: localize('accessibilitySignals.diffLineDeleted', 'Diff Line Deleted'),
      sound: Sound.diffLineDeleted,
      legacySoundSettingsKey: 'audioCues.diffLineDeleted',
      settingsKey: 'accessibility.signals.diffLineDeleted'
    });
  }
  static {
    this.diffLineModified = AccessibilitySignal.register({
      name: localize('accessibilitySignals.diffLineModified', 'Diff Line Modified'),
      sound: Sound.diffLineModified,
      legacySoundSettingsKey: 'audioCues.diffLineModified',
      settingsKey: 'accessibility.signals.diffLineModified'
    });
  }
  static {
    this.chatRequestSent = AccessibilitySignal.register({
      name: localize('accessibilitySignals.chatRequestSent', 'Chat Request Sent'),
      sound: Sound.chatRequestSent,
      legacySoundSettingsKey: 'audioCues.chatRequestSent',
      legacyAnnouncementSettingsKey: 'accessibility.alert.chatRequestSent',
      announcementMessage: localize('accessibility.signals.chatRequestSent', 'Chat Request Sent'),
      settingsKey: 'accessibility.signals.chatRequestSent'
    });
  }
  static {
    this.chatResponseReceived = AccessibilitySignal.register({
      name: localize('accessibilitySignals.chatResponseReceived', 'Chat Response Received'),
      legacySoundSettingsKey: 'audioCues.chatResponseReceived',
      sound: {
        randomOneOf: [Sound.chatResponseReceived1, Sound.chatResponseReceived2, Sound.chatResponseReceived3, Sound.chatResponseReceived4]
      },
      settingsKey: 'accessibility.signals.chatResponseReceived'
    });
  }
  static {
    this.progress = AccessibilitySignal.register({
      name: localize('accessibilitySignals.progress', 'Progress'),
      sound: Sound.progress,
      legacySoundSettingsKey: 'audioCues.chatResponsePending',
      legacyAnnouncementSettingsKey: 'accessibility.alert.progress',
      announcementMessage: localize('accessibility.signals.progress', 'Progress'),
      settingsKey: 'accessibility.signals.progress'
    });
  }
  static {
    this.clear = AccessibilitySignal.register({
      name: localize('accessibilitySignals.clear', 'Clear'),
      sound: Sound.clear,
      legacySoundSettingsKey: 'audioCues.clear',
      legacyAnnouncementSettingsKey: 'accessibility.alert.clear',
      announcementMessage: localize('accessibility.signals.clear', 'Clear'),
      settingsKey: 'accessibility.signals.clear'
    });
  }
  static {
    this.save = AccessibilitySignal.register({
      name: localize('accessibilitySignals.save', 'Save'),
      sound: Sound.save,
      legacySoundSettingsKey: 'audioCues.save',
      legacyAnnouncementSettingsKey: 'accessibility.alert.save',
      announcementMessage: localize('accessibility.signals.save', 'Save'),
      settingsKey: 'accessibility.signals.save'
    });
  }
  static {
    this.format = AccessibilitySignal.register({
      name: localize('accessibilitySignals.format', 'Format'),
      sound: Sound.format,
      legacySoundSettingsKey: 'audioCues.format',
      legacyAnnouncementSettingsKey: 'accessibility.alert.format',
      announcementMessage: localize('accessibility.signals.format', 'Format'),
      settingsKey: 'accessibility.signals.format'
    });
  }
  static {
    this.voiceRecordingStarted = AccessibilitySignal.register({
      name: localize('accessibilitySignals.voiceRecordingStarted', 'Voice Recording Started'),
      sound: Sound.voiceRecordingStarted,
      legacySoundSettingsKey: 'audioCues.voiceRecordingStarted',
      settingsKey: 'accessibility.signals.voiceRecordingStarted'
    });
  }
  static {
    this.voiceRecordingStopped = AccessibilitySignal.register({
      name: localize('accessibilitySignals.voiceRecordingStopped', 'Voice Recording Stopped'),
      sound: Sound.voiceRecordingStopped,
      legacySoundSettingsKey: 'audioCues.voiceRecordingStopped',
      settingsKey: 'accessibility.signals.voiceRecordingStopped'
    });
  }
}